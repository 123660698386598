export const appStoreUrl = 'https://apps.apple.com/id/app/lion-parcel/id1455977134';
export const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.lionparcel.services.consumer&hl=in';

export const getOS = () => {
  const { userAgent } = window.navigator;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (_checkPlatform(macosPlatforms, userAgent)) {
    os = 'Mac OS';
  } else if (_checkPlatform(iosPlatforms, userAgent)) {
    os = 'iOS';
  } else if (_checkPlatform(windowsPlatforms, userAgent)) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(userAgent)) {
    os = 'Linux';
  }

  return os;
};
const _checkPlatform = (platforms, userAgent) => {
  const result = [];
  platforms.forEach((platform) => {
    const regex = new RegExp(platform);
    result.push(regex.test(userAgent));
  });

  return result.includes(true);
};

export const isAndroid = () => getOS() === 'Android';

export const isIOS = () => getOS() === 'iOS';

export const isMac = () => getOS() === 'Mac OS';

export const isWindows = () => getOS() === 'Windows';

export const isLinux = () => getOS() === 'Linux';

export const getStoreUrlByOS = () => (isAndroid() ? playStoreUrl : appStoreUrl);
