<template>    
  <Modal
    :header="true"
    :footer="true"
    width="500px;"
    @close="toggleDownloadModal"
  >
    <template #header>
      <div class="download-modal__head">
        <h1 class="download-modal__head__title">
          Ayo, Lanjutkan Kirim Paket Anda dengan Download Aplikasinya Sekarang!
        </h1>
        <p class="download-modal__head__subtitle">
          Nikmati kemudahan pengiriman, banyak promo menarik & dapatkan keuntungan membershipnya!
        </p>
      </div>
    </template>
    <div class="download-modal__body">
      <h1 class="download-modal__body__title">
        Scan QR Code untuk download aplikasi Lion Parcel dari Playstore atau Appstore
      </h1>
      <img
        src="/images/tariff/prod-qrcode.png"
        class="download-modal__body__qrcode"
        alt="qrcode_download"
      >
      <div class="download-modal__body__appstore-playstore">
        <img
          id="Download_Google_Play_Popup"
          src="/images/playstore.png"
          class="pointer"
          alt="playstore"
          @click="redirectToPlaystore"
        >
        <img
          id="Download_Appstore_Popup"
          src="/images/appstore.png"
          class="pointer"
          alt="appstore"
          @click="redirectToAppStore"
        >
      </div>
    </div>
    <template #footer>
      <div class="download-modal__footer">
        <h1 class="download-modal__footer__title">
          Cara download aplikasi dengan Scan QR
        </h1>
        <div class="download-modal__footer__instruction">
          <div class="download-modal__footer__instruction__content">
            <img
              src="/images/tariff/download-instruction1.svg"
              alt="open-scanner"
            >
            <div>
              <h1>
                Buka kamera HP
              </h1>
              <p>
                Aktifkan fitur Scan QR Code di pengaturan kamera
              </p>
            </div>
          </div>
          <div class="download-modal__footer__instruction__content">
            <img
              src="/images/tariff/download-instruction2.svg"
              alt="open-scanner"
            >
            <div>
              <h1>
                Notifkasi
              </h1>
              <p>
                Klik notifikasi untuk langsung mendownload aplikasi
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/new-modal/Modal.vue";
import mixinMobile from "@/misc/mixinMobile.js";
import { appStoreUrl, playStoreUrl } from '@/pages/new-merchant/app/utils/getOs';

export default {
  name: "ModalDownload",
  components: { Modal },
  mixins: [mixinMobile],
  methods: {
    toggleDownloadModal() {
      this.$emit("close")
    },
    redirectToPlaystore() {
      window.open(playStoreUrl, "_blank")
    },
    redirectToAppStore() {
      window.open(appStoreUrl, "_blank")
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/style";
@import "@/assets/css/flexbox";
@import "@/assets/css/spacing";

@mixin CustomFontSize($font-size: 34px, $line-height: 51px) {
  font-size: $font-size;
  line-height: $line-height;
}

.download-modal {
  &__head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 18px;

    &__title {
      width: 380px;
      @include CustomFontSize(0.875rem, 1.3125rem);
      font-weight: bold;
      text-align: center;
      color: $color-base-text;
    }

    &__subtitle {
      width: 360px;
      text-align: center;
      color: $color-text-small;
      @include CustomFontSize(0.625rem, 0.9375rem);
    }

    &__divider {
      height: 2px;
      background-color: $color-white-dark;
      width: 100%;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;

    &__title {
      @include CustomFontSize(0.625rem, 0.9375rem);
      font-weight: 600;
      text-align: center;
      color: $color-base-text;
    }

    &__qrcode {
      width: 120px;
      height: 120px;
    }

    &__appstore-playstore {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;

      img {
        width: 69.5px;
        height: 25.5px;
      }
    }
  }

  &__footer {
    background-color: #FEE8E84D;
    width: 100%;
    padding-bottom: 24px;

    &__title {
      padding: 14px;
      @include CustomFontSize(0.625rem, 0.9375rem);
      color: $color-base-text;
      font-weight: 600;
      text-align: center;
    }

    &__instruction {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 25px;

      &__content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
                
        div {
          h1 {
            @include CustomFontSize(0.5625rem, 0.84375rem);
            font-weight: 600;
            color: $color-base-text;
          }

          p {
            @include CustomFontSize(0.5rem, 0.75rem);
            color: $color-base-text;
            width: 115px;
          }
        }
      }
    }
  }
}
</style>
